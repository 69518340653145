<script>
import { get, debounce } from "lodash";
import { fandomBasicMixin } from "src/modules/fandom_mixins_module.js";
import { disableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock';

export default {
  mixins: [fandomBasicMixin],
  data() {
    return {
      gradientBackground: false,
      sidebar: false
    };
  },
  methods: {
    mayAnchorTo(item, event) {
      const path = window.location.pathname;
      if (item.url.url.startsWith("#") || item.url.url.startsWith(`${path}#`)) {
        this.toggleSidebar();
        Vue.nextTick(() => {
          const position = $(item.url.url.replace(path, "")).offset().top - $("#main-navbar").outerHeight();
          $("html, body").animate({ scrollTop: position }, 0, "linear");
        })

        event.preventDefault();
        return false;
      } else {
        return true;
      }
    },
    isItemActive(item) {
      // When url is a ContentLink extra field type the content name can be used as matching 
      // name when mathing name is missing. 
      if (item.url.content && !item.matching_name) {
        item.matching_name = item.url.content;
      }
      const re = new RegExp(`^(${item.matching_name})$`);
      return re.test(globalState.pageModel.main_content_name);
    },
    toggleSidebar() {
      if (this.sidebar) {
        clearAllBodyScrollLocks();
        this.sidebar = false;
      } else {
        disableBodyScroll(this.$refs["sidebar"]);
        this.sidebar = true;
      }
    },
    itemId(item) {
      return `main-navbar-item-${item.$id}`;
    },
    isUrlCollapse(item) {
      return item.url.url == '$collapse';
    }
  },
  computed: {
    isUserRegistered() {
      return Fandom.isUserRegistered();
    },
    username() {
      const user = globalState.pageModel.user;
      return user.first_name.charAt(0);
    },
    items() {
      return get(globalState, ["pageModel", "layout", "header", "items"], []); 
    },
    logoAlt() {
      return get(this.pageModel, ['seo', 'title'], '');
    }
  },
};
</script>

<template>
  <div class="fixed-top">
    <nav id="main-navbar" class="navbar navbar-expand-lg justify-content-between px-3 px-sm-4" navbar-component>
      <div class="d-flex">
        <div v-if="items.length > 0" class="d-flex justify-content-center align-items-center cursor-pointer text-white" @click="toggleSidebar">
          <i class="fal fa-bars fa-2x"></i>
        </div>
        <a :class="{'mx-4': items.length > 0}" class="navbar-brand d-flex align-items-center" :href="applyContextToUrl('/')">
          <img :src="content.logo.url" class="navbar-logo" :alt="logoAlt"/>
        </a>
      </div>
      <div class="nav-item" v-if="isUserRegistered">
        <div class="username-logo d-flex align-items-center text-uppercase justify-content-center">{{username}}</div>
      </div>
    </nav>
    <transition enter-active-class="animated faster fadeIn" leave-active-class="animated faster fadeOut">
      <div class="position-fixed" @click="toggleSidebar" v-if="sidebar" sidebar-layer></div>
    </transition>
    <transition enter-active-class="animated faster slideInLeft" leave-active-class="animated faster slideOutLeft">
      <div class="position-fixed bg-primary" ref="sidebar" v-if="sidebar" sidebar>
        <div class="d-flex flex-column h-100">
          <div class="flex-grow-1 sidebar-content">
            <div class="d-flex align-items-start justify-content-between">
              <img :src="content.logo.url" class="sidebar-logo" :alt="logoAlt"/>
              <div class="close-icon cursor-pointer text-white">
                <i class="fal fa-times fa-2x" @click="toggleSidebar"></i>
              </div>
            </div>
            <div class="py-4">
              <div class="py-3 border-bottom nav-item" v-for="(item, index) in items" :key="`sidebar-item-${index}`">
                <a
                  class="nav-item-url font-weight-bold d-flex align-items-center justify-content-between"
                  :href="isUrlCollapse(item) ? `#${itemId(item)}` : item.url.url"
                  :target="isUrlCollapse(item) ? '_self' : getTarget(item.url)"
                  :aria-controls="isUrlCollapse(item) ? itemId(item) : null"
                  :data-toggle="isUrlCollapse(item) ? 'collapse' : null"
                  :role="isUrlCollapse(item) ? 'button' : null"
                  :aria-expanded="isUrlCollapse(item) ? 'false' : null"
                  @click="mayAnchorTo(item, $event)"
                >
                  <span>{{item.url.title}}</span>
                  <a class="nav-item-url font-weight-bold collapsed collapse-toggle d-flex justify-content-end" data-toggle="collapse" role="button" aria-expanded="false" :aria-controls="itemId(item)" v-if="item.items" :href="`#${itemId(item)}`">
                    <i class="fal fa-chevron-up"></i>
                  </a>
                </a>
                <div class="collapse multi-collapse" :id="itemId(item)" v-if="item.items">
                  <ul class="list-unstyled mb-0 mt-2">
                    <li v-for="(subitem, subindex) in item.items" :key="'index-' + index + '-subindex-' + subindex">
                      <a class="nav-item-url my-2 ml-2 text-normal" :href="subitem.url.url" :target="getTarget(subitem.url)" >
                        {{subitem.url.title}}
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div class="sidebar-footer nav-item py-4">
            <a v-if="!isUserRegistered" class="nav-item-url d-flex align-items-center font-weight-bold deco-none" href="/users/sign_in">
              {{ft('globals.navbar.sign_in')}}
            </a>
            <a v-else class="nav-item-url d-flex align-items-center font-weight-bold" href="/users/sign_out">
              {{ft('globals.navbar.sign_out')}}
            </a>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<style lang="scss" scoped>

.navbar {
  background: $primary !important;
}

[navbar-component] {
  min-height: $header-height-sm;
  transition: all $animation-time-fast linear;
  box-shadow: 0 0.1rem 0.5rem rgba(0, 0, 0, 0.15) !important;

  @include media-breakpoint-up(md) {
    background: transparent;
  }

  .navbar-brand {
    min-height: calc(#{$header-height-sm} - 1rem);
  }

  .username-logo {
    border-radius: 100%;
    background-color: $white;
    color: $primary;
    width: 2.75rem;
    height: 2.75rem;
    font-size: 1.5rem;
  }

  .navbar-logo {
    height: 2.75rem;
  }

  .sidebar-toggler, .close-icon {
    transition: color .25s;
    
    &:hover {
      color: $gray-600;
    }
  }
}

[navbar-component] .nav-item,
[sidebar] .nav-item {
  font-size: .9rem !important;
  letter-spacing: 1px;
  text-decoration: none !important;

  .nav-item-url {
    color: $white;
    text-decoration: none;
    transition: color .25s;
    
    &.collapsed .fal {
      transform: rotate(180deg);
    }
  }

  &:hover,
  &:active,
  &:focus,
  &.active {
    .nav-item-url {
      color: $gray-800;
    }
  }
}

[sidebar-layer] {
  z-index: $zindex-sticky + 1;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba($dark, .5);
}

[sidebar] {
  z-index: $zindex-sticky + 1;
  top: 0;
  left: 0;
  bottom: 0;
  width: 100%;
  font-size: .875rem;
  max-width: 17.5rem;
  padding-top: 1.5rem;
  overflow-y: auto;

  .user-icon {
    width: 1.5rem;
    height: 1.5rem;
  }

  .sidebar-footer {
    background-color: $white;

    &.nav-item:hover .nav-item-url {
      color: $gray-800 !important;
    }

    &.nav-item .nav-item-url {
      color: $primary !important;
    }
  }

  .sidebar-content,
  .sidebar-footer {
    padding-left: 1.25rem;
    padding-right: 1.25rem;
  }

  .close-icon {
    top: 0;
    right: 0;
  }

  .sidebar-logo {
    height: 2.75rem;
  }

  .text-normal {
    text-transform: none;
  }

  .collapse-toggle {
    min-width: 25%;
  }

  .border-bottom {
    border-color: $white !important;
  }
}
</style>
