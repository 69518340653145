<script>
import { fandomBasicMixin } from "src/modules/fandom_mixins_module.js";

export default {
  mixins: [fandomBasicMixin],
  data() {
    return {
      userBadges: null,
      activeBadge: null,
    };
  },
  mounted() {
    this.getUserBadges();
  },
  methods: {
    getUserBadges() {
      const publicId = Fandom.getUrlParameter("public_id");
      Fandom.ajax({
        method: "GET",
        data: {
          public_id: publicId
        },
        url: "/api/v5/get_user_badges",
        success: (data) => {
          this.userBadges = data;
        }
      });
    },
    isBadgeActive(badge) {
      return this.userBadges.user_reward_ids.indexOf(badge.id) > -1;
    },
    isBadgeInactive(badge) {
      return !this.isBadgeActive(badge);
    },
    hasIcon(item) {
      return Fandom.exists(item.image) || Fandom.exists(item.icon);
    },
    openModal(badge) {
      this.activeBadge = badge;
      $(this.$refs["badge-modal"]).modal("show");
    },
    closeModal() {
      this.activeBadge = null;
      $(this.$refs["badge-modal"]).modal("hide");
    }
  }
};
</script>

<template>
  <div v-easyadmin="easyadminId" :class="contentType" user-badges-component>
    <div class="container py-3">
      <div class="row justify-content-center">
        <div class="col-sm-10">
          <div class="row" v-if="userBadges">
            <div v-for="(badge, index) in userBadges.rewards" class="_content col-md-3 col-sm-6 py-3" v-easyadmin="badge.name" :key="index">
              <div class="ar-1by1 user-badge position-relative text-center cursor-pointer" @click="openModal(badge)">
                <div class="position-absolute-center d-flex flex-column align-items-center justify-content-center" :class="{ 'inactive': isBadgeInactive(badge) }">
                  <div class="user-badge-icon d-flex align-items-center justify-content-center m-3" v-if="hasIcon(badge)">
                    <img class="icon-image disabled-image" :src="badge.inactive_image.url" v-if="isBadgeInactive(badge) && badge.inactive_image">
                    <img class="icon-image" :src="badge.image.url" v-else-if="badge.image">
                    <i :class="badge.icon" v-else-if="badge.icon"></i>
                  </div>
                  <p v-if="badge.title">{{badge.title}}</p>
                </div>
              </div>
            </div>
          </div>
          <h2 class="text-center" v-else>{{ft("globals.no_badges")}}</h2>
        </div>  
      </div>
    </div>

    <div class="modal fade" ref="badge-modal" tabindex="-1" role="dialog" v-if="activeBadge">
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header border-0">
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="row justify-content-center">
              <div class="col-auto">
                <h5 class="modal-title h2">{{activeBadge.title}}</h5>
              </div>
            </div>
            <div class="row justify-content-center">
              <div class="col-auto">
                <div class="d-flex align-items-center justify-content-center m-3 modal-icon" :class="{ 'inactive': isBadgeInactive(activeBadge) }">
                  <img class="icon-image disabled-image" :src="activeBadge.inactive_image.url" v-if="isBadgeInactive(activeBadge) && activeBadge.inactive_image">
                  <img class="icon-image" :src="activeBadge.image.url" v-else-if="activeBadge.image">
                  <i :class="activeBadge.icon" v-else-if="activeBadge.icon"></i>
                </div>
              </div>
            </div>
            <div class="row justify-content-center">
              <div class="col-auto">
                <p v-if="activeBadge.description" v-html="activeBadge.description"></p>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" data-dismiss="modal">{{ft("globals.close")}}</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
[user-badges-component] {

  .modal-icon {
    width: $user-badge-modal-icon-sizing;
    height: $user-badge-modal-icon-sizing;
    border-radius: 100%;
    font-size: $user-badge-modal-icon-font-size;
    background-color: $user-badge-color;
    color: $user-badge-icon-color;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    &.inactive {
      opacity: 0.5;
    }
  }

  .icon-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .user-badge {
    background-color: $user-badge-bg-color;
    color: $user-badge-color;

    .inactive {
      opacity: 0.5;
    }

    .user-badge-icon {
      background-color: $user-badge-color; // reverse
      color: $user-badge-icon-color; // reverse
      border-radius: 100%;
      height: $user-badge-icon-sizing;
      width: $user-badge-icon-sizing;
      font-size: $user-badge-icon-font-size;
    }
  }
}
</style>
