<script>
import { fandomBasicMixin } from 'src/modules/fandom_mixins_module.js';
import { get } from "lodash";
import { Fandom } from '../../modules/fandom_module';

export default {
  mixins: [fandomBasicMixin],
  data: function () {
    return {
      userInfo: null,
      countableRewardInfoList: [
        { name: "point", icon: "fal fa-star" }, 
        { name: "ticket", icon: "fal fa-ticket-alt fa-rotate-90" }
      ]
    };
  },
  mounted() {
    this.getUserInfo();
  },
  computed: {
    userInitials() {
      const user = get(this.globalState.pageModel, ["user"], {});
      return `${user.first_name?.[0] ?? "A"}${user.last_name?.[0] ?? "A"}`;
    }
  },
  methods: {
    getBackgroundUrl: Fandom.getBackgroundUrl,
    getCountableRewardCounter(countableRewardName) {
      return get(this.userInfo, ["reward_name_to_counter", countableRewardName], null);
    },
    getUserInfo() {
      Fandom.ajax({
        method: "GET",
        data: {
          container_content_id: this.containerContent.id,
          content_id: this.content.$id // it could be nil in the case of non-record content
        },
        url: "/api/v5/get_user_info",
        success: (data) => {
          this.userInfo = data;
        }
      });
    }
  }
};
</script>

<template>
  <div v-easyadmin="easyadminId" :class="contentType" class="border-bottom" user-dashboard-component>
    <div class="container" v-if="userInfo">
      <div class="row user-dashboard-content align-items-center">
        <div class="col-auto">
          <div class="bg-cover-center user-dashboard-avatar position-relative rounded-circle" :style="{'background-image': getBackgroundUrl(userInfo.avatar)}">
            <div v-if="!userInfo.avatar" class="position-absolute-center d-flex align-items-center justify-content-center">
              {{userInitials}}
            </div>
          </div>
        </div>
        <div class="col">
          <h1 class="m-0 p-0 h2 font-weight-normal">{{`${userInfo.first_name} ${userInfo.last_name}`}}</h1>
        </div>
        <div class="col-md-auto d-flex flex-wrap py-5 px-3">
          <div class="d-flex align-items-center px-3 py-2" v-for="item in userInfo.recap" v-if="userInfo.recap">
            <div class="icon d-flex align-items-center justify-content-center mr-2">
              <i :class="item.group_icon" class="fa-2x"></i>
            </div>
            <div>
              <p class="font-weight-normal m-0">{{item.last_user_reward.title}}</p>
              <p class="m-0 text-uppercase">{{item.group_title}}</p>
            </div>
          </div>
          <div class="d-flex align-items-center px-3" v-for="item in countableRewardInfoList">
            <div class="icon d-flex align-items-center justify-content-center mr-3">
              <i :class="item.icon" class="fa-2x"></i>
            </div>
            <div>
              <p class="font-weight-normal m-0 h3">{{getCountableRewardCounter(item.name) || 0}}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-else class="user-dashboard-content"></div>
  </div>
</template>

<style lang="scss" scoped>
[user-dashboard-component] {
  background-color: $user-dashboard-bg-color;

  .user-dashboard-content {
    height: $user-dashboard-height;

    .user-dashboard-avatar {
      width: 6rem;
      height: 6rem;
      font-size: 2rem;
      overflow: hidden;
      color: $user-dashboard-avatar-color;
      background-color: $user-dashboard-avatar-bg-color;
    }
  }
}
</style>
