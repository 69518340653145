<template>
  <list-carousel
    v-if="currentIndex >= 0"
    :content="content"
    :opt-container-content="optContainerContent"
    :reset-answer="resetAnswer"
    :slide-config="{prevNextButtons: false, draggable: false, pageDots: false}"
    container-class="container-fluid"
    @slider-ready="handleSliderReady"
    @slider-change="handleSliderChange"
    @slider-settle="handleSliderSettle"
    @user-interaction-updated="handleUserInteraction"
    @interaction-handler="handleInteraction"
    list-carousel-interactive-component
  ></list-carousel>
</template>

<script>
import { fandomBasicMixin } from "src/modules/fandom_mixins_module.js";
import { interactionMixin } from 'src/modules/interactions_module.js';

export default {
  mixins: [fandomBasicMixin, interactionMixin],
  data: function () {
    return { 
      slider: null,
      timeoutID: null,
      intervalID: null,
      resetAnswer: false,
      currentIndex: -1,
      countAnswer: 0,
      firstUnansweredQuestion: -1,
      paddingYDefault: 4
    };
  },
  mounted() {
    this.initAnswerCounter();
  },
  methods: {
    initAnswerCounter() {
      const itemInteractionIDs = this.interactions
        .filter(item => typeof item.interaction !== 'undefined')
        .map(item => `${item.interaction.id}`);

      const contentUserInfo = this.getContentUserInfo(this.containerContent);
      Object.keys(contentUserInfo.id_to_interaction).forEach((key) => {
        if (itemInteractionIDs.includes(key)) {
          const interaction = contentUserInfo.id_to_interaction[key];
          if (interaction.interaction_type != "Test") {
            if (this.exists(interaction.user_interaction)) {
              this.countAnswer++;
            } else if (this.firstUnansweredQuestion === -1) {
              this.firstUnansweredQuestion = key;
            }
          }
        }
      });

      if (this.firstUnansweredQuestion !== -1) {
        this.currentIndex = this.interactions.findIndex( item => (item.interaction?.id ?? false) == this.firstUnansweredQuestion)
      } else {
        this.currentIndex = this.interactions.length-1;
      }
    },
    handleSliderReady(slider) {
      this.slider = slider;
      if (this.countAnswer === this.totalQuestions) {
        this.currentIndex = 0;
        this.slider.select(this.currentIndex, false, true);
        this.intervalID = setInterval(this.nextInteraction, 3000);
      } else {
        this.slider.select(this.currentIndex, false, true);

        if (this.currentIndex > 0) {
          // Intersect workaround 
          const tmp = document.documentElement.scrollTop; 
          document.documentElement.scrollTop = 0; 
          setTimeout(() => {document.documentElement.scrollTop = tmp }, 0);
        }
      }
    },
    handleSliderChange(index) {
      clearTimeout(this.timeoutID);
      this.timeoutID = null;
      if (index > 0) {
        this.currentIndex = index;
      }
    },
    handleSliderSettle() {
      this.slider.resize();
      $(".carousel-body").scrollTop = 0;
    },
    handleUserInteraction(data) {
      if (data.interaction.type != "Test") {
        this.timeoutID = setTimeout(() => {
          this.countAnswer++;
          this.nextInteraction();
        }, 1000);
      }
    },
    nextInteraction() {
      this.timeoutID = null;
      if (this.slider.selectedIndex === this.interactions.length-1) {
        clearInterval(this.intervalID);
        this.$emit('interaction-done');
      } else {
        this.slider.next();
      }
    },
    handleInteraction(data) {
      if (data.action == "reset") {
        this.countAnswer = 0;
        this.resetAnswer = true;
        this.currentIndex = 0;
        Vue.nextTick(() => {
          this.slider.select(0, false, true);
          this.resetAnswer = false;
        })
      }
    }
  },
  computed: {
    totalQuestions() {
      return this.interactions.reduce((acc, item) => {
        return acc += item.interaction && item.interaction.type != "Test" ? 1 : 0
      }, 0);
    },
    interactions() {
      return this.content.items;
    },
    progressStyle() {
      return {
        width: `${this.countAnswer / this.totalQuestions * 100}%`
      }
    }
  }
};

</script>

<style lang="scss" scoped>
::v-deep {
  .flickity-prev-next-button { display: none; }
  .flickity-carousel-item { 
    min-width: 300px; 
    padding-top: 1.5rem !important;  
    padding-bottom: 1.5rem !important;
  }

  [interaction-trivia-component] {
    .answers {
      justify-content: center;
    }

    button {
      @include media-breakpoint-up(md) {
        // force 2 lines
        height: 100px;
      }
    }
  }
}

.carousel-header {
  position: relative;

  .progress {
    height: 6px;
    width: 40%;
  }
}
</style>