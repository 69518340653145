<script>
import { fandomBasicMixin } from 'src/modules/fandom_mixins_module.js';

export default {
  mixins: [fandomBasicMixin],
  mounted() {
    this.ticketize("title");
    this.ticketize("description");
  },
  methods: {
    ticketize(attr) {
      if (this.content[attr]) {
        let ticket = globalState.pageModel?.user?.reward_name_to_counter?.ticket || 0;
        if (!Fandom.isUserRegistered())
          ticket = ticket + 1;
        this.content[attr] = this.content[attr].replace("[ticket]", ticket);
      }
    }
  } 
};
</script>

<template>
  <stripe-text :content="content" :opt-container-content="containerContent"></stripe-text>
</template>

<style lang="scss">
</style>
