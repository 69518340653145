<script>
import { fandomBasicMixin } from "src/modules/fandom_mixins_module.js";

export default {
  mixins: [fandomBasicMixin],
  methods: {
    getTarget: Fandom.getTarget
  }
};
</script>

<template>
  <div footer-component class="footer">
    <component v-if="content.stripes" :is="stripe.layout" :content="stripe" v-for="stripe in content.stripes" :key="stripe.$id" :opt-container-content="content"></component>
    <div class="container-fluid px-3 px-sm-4 small" footer-component>
      <div class="row d-flex align-items-center flex-column-reverse flex-md-row py-4">
        <div class="col-12 col-md-6">
          <div class="footer-description mb-1 text-white" v-html="content.description"></div>
          <p class="mb-0 text-white">
            This site is protected by reCAPTCHA and the Google 
            <a href="https://policies.google.com/privacy" target="_blank" class="font-weight-normal">Privacy Policy</a> and 
            <a href="https://policies.google.com/terms" target="_blank" class="font-weight-normal">Terms of Service</a> apply.
          </p>
        </div>
        <div class="col-12 col-md-6">
          <div class="d-flex align-items-center justify-content-start justify-content-md-end mb-3 mb-md-0">
            <a
              v-for="(item, index) in content.navbar_bottom"
              :href="item.url.url" :target="getTarget(item.url)"
              :class="!index ? '' : 'ml-3'"
              class="navbar-link font-weight-bold">
              {{item.url.title}}
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss">
[footer-component] {
  background-color: $gray-800;

  .footer-description {
    p:last-child {
      margin-bottom: 0;
    }
  }
}
</style>
