<template>
  <div v-easyadmin="easyadminId" :style="rootStyle" :class="[contentType, {'fullscreen': this.content.fullscreen}]" class="position-relative bg-cover-center" page-event-interactive-standalone>
    <div class="p-0 p-lg-2 h-100">
      <event-interactive-sidebar
        class="rounded position-relative overflow-hidden"
        :tabs="tabs"
        :content="eventContent"
        :is-standalone="true"
      ></event-interactive-sidebar>
      <page-live-event-alert v-if="hasAlert" class="modal-generic" v-model="hasAlert" :content="eventContent.alert" :opt-container-content="containerContent"></page-live-event-alert>
    </div>
  </div>
</template>

<script>
import { fandomBasicMixin, fandomExtendedMixin } from 'src/modules/fandom_mixins_module.js';
import { ActionCableComponentsMixin } from 'src/modules/websocket_module.js';
import EventInteractiveSidebar from 'event-interactive-sidebar';
import PageLiveEventAlert from 'page-live-event-alert';

import { debounce } from 'lodash';

export default {
  mixins: [fandomBasicMixin, fandomExtendedMixin, ActionCableComponentsMixin],
  components: { PageLiveEventAlert, EventInteractiveSidebar },
  data: function() {
    return {
      showAlert: typeof this.content.alert !== 'undefined'
    }
  },
  mounted() {
    this.updateWindowHeight();
    window.addEventListener('resize', debounce(() => this.updateWindowHeight(), 250));
    this.initSubscriptions();
  },
  methods: {
    updateWindowHeight() {
      // SAFARI WORKAROUND TO TRIGGER VIEWPORT RESIZE
      const vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty('--vh', `${vh}px`);
    },
    initSubscriptions() {
      this.subscribe(
        { channel: "PublicChannel", room: this.containerContent.name, lang: globalState.lang },
        { received: (data) => this.receivedChannelData(this.containerContent.name, data) }
      );
      this.subscribe(
        { channel: "PublicChannel", room: this.content.content, lang: globalState.lang },
        { received: (data) => this.receivedChannelData(this.content.content, data) }
      );
      this.subscribe({ channel: "TrackingChannel", room: this.containerContent.name, lang: globalState.lang });
    },
    receivedChannelData(contentName, receivedData) {
      if (receivedData) {
        Fandom.getContentsCallback(contentName, receivedData, (data) => {
          const newContent = data.name_to_content[data.main_content_name];
          Vue.set(globalState.pageModel.name_to_content, data.main_content_name, newContent);
        });
      }
    }
  },
  computed: {
    eventContent() {
      const eventContentName = this.content.content;
      return {...globalState.pageModel.name_to_content?.[eventContentName], theme: this.content.theme};
    },
    tabs() {
      return this.content.tabs.split(',');
    },
    rootStyle() {
      return {
        "background-image": this.getContentResponsiveImageURL("background_image", this.eventContent.play),
        "background-color": "black"
      };
    },
    hasAlert() {
      return this.eventContent.alert?.valid ?? false;
    }
  }
};
</script>

<style lang="scss">
[page-event-interactive-standalone] {
  --inner-height: calc(var(--vh, 1vh) * 100);

  &.fullscreen {
    position: fixed !important;
    display: block;
    top: 0;
    left: 0;
    right: 0;
    z-index: 1090;
    height: var(--inner-height);
    min-height: var(--inner-height);
    max-height: var(--inner-height);
    flex: 1 1 var(--inner-height);
  }

  .rounded {
    border-radius: $player-border-radius !important;
  }
}
</style>
