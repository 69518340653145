<script>
import { fandomBasicMixin } from "src/modules/fandom_mixins_module.js";

export default {
  mixins: [fandomBasicMixin],
  data() {
    return {
      userLevels: null
    };
  },
  mounted() {
    this.getUserLevels();
  },
  methods: {
    getUserLevels() {
      const publicId = Fandom.getUrlParameter("public_id");
      Fandom.ajax({
        method: "GET",
        data: {
          public_id: publicId
        },
        url: "/api/v5/get_user_levels",
        success: (data) => {
          this.userLevels = data;
        }
      });
    },
    hasIcon(item) {
      return Fandom.exists(item.image) || Fandom.exists(item.icon);
    },
    getLevelStrokeDashOffset: function(level) {
      const progress = this.userLevels.id_to_user_reward_progress[level.id];
      return 365 - (365 * progress / 100);
    },
  },
  computed: {
    pointsStyle() {
      return {
        "background-color": this.propertyInfo?.color
      };
    }
  }
};
</script>

<template>
  <div v-easyadmin="easyadminId" :class="contentType" user-levels-component>
    <!-- margin management -->
    <div class="container py-5">
      <div class="row justify-content-center">
        <div class="col-sm-10">
          <div class="row" v-if="userLevels">
            <div v-for="(level, index) in userLevels.currency_to_rewards['point']" class="_content col-lg-3 col-md-4 col-sm-6 py-3" v-easyadmin="level.name" :key="index">
              <div class="ar-1by1 user-level position-relative text-center">
                <div class="position-absolute-center d-flex flex-column align-items-center justify-content-center" :class="{'inactive': userLevels.user_reward_ids.indexOf(level.id) == -1}">
                  <div class="user-level-icon d-flex align-items-center justify-content-center m-3 position-relative" v-if="hasIcon(level)">
                    <img class="img-fluid" :src="level.image.url" v-if="level.image">
                    <i :class="level.icon" v-else-if="level.icon"></i>
                    <svg viewBox="0 0 120 120" class="position-absolute-center">
                      <circle class="svg-bg" cx="60" cy="60" r="58" stroke-width="8" fill="none" transform="rotate(-90, 60, 60)" stroke-dashoffset="0" stroke-dasharray="365"/>
                      <circle class="svg-progress" cx="60" cy="60" r="58" stroke-width="8" fill="none" transform="rotate(-90, 60, 60)" :style="{'stroke-dashoffset': getLevelStrokeDashOffset(level)}" stroke-dasharray="365"/>
                    </svg>
                  </div>
                  <h3 v-if="level.title" class="user-level-title mx-2">{{level.title}}</h3>
                  <p v-if="!(level.cost && level.cost.amount) && level.description" class="user-level-description mx-2" :style="pointsStyle" v-html="level.description"></p>
                  <div v-if="level.cost && level.cost.amount" class="user-level-description mx-2 mb-3" :style="pointsStyle">
                    {{level.cost.amount}}<i class="fas fa-star ml-2"></i>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <h2 class="text-center" v-else>{{ft("globals.no_levels")}}</h2>
        </div>  
      </div>
    </div>
  </div>
</template>

<style lang="scss">
[user-levels-component] {

  .user-level {
    background-color: $user-level-bg-color;
    color: $user-level-color;

    .inactive {
      background-color: $user-level-inactive-bg-color;
    }

    .user-level-icon {
      background-color: $user-level-color; // reverse
      color: $user-level-icon-color; // reverse
      border-radius: 100%;
      height: $user-level-icon-sizing;
      width: $user-level-icon-sizing;
      font-size: $user-level-icon-font-size;

      svg {
        overflow: visible;

        .svg-bg {
          stroke: $user-level-stroke-bg-color;
        }

        .svg-progress {
          stroke: $user-level-stroke-color;
        }
      }
    }

    .user-level-title {
      font-size: 1rem;
    }

    .user-level-description {
      padding: .5rem 1rem;
      border-radius: 1.5rem;
      color: $user-level-description-color;
      font-size: 0.75rem;
      p {
        margin-bottom: 0 !important;
      }
    }
  }
}
</style>
